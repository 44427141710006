


























































import Vue from 'vue'
import { Project, Requisition, ApiResponse } from '@/interfaces'

interface Badge {
  color: string
  value: string
}

interface DropdownAction {
  action: string
  requisition?: Requisition
  project?: Project
}

export default Vue.extend({
  name: 'RequisitionsTable',
  props: {
    requisitions: {
      type: Array,
      required: false,
      default: () => [] as Requisition[],
    },
    page: {
      type: String,
      required: false,
      default: null,
    },
    view: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    statusFilters() {
      return [
        { text: 'En cours', value: 'en_cours' },
        { text: 'Clos', value: 'clos' },
      ]
    },
  },
  methods: {
    sortChange() {
      this.$emit('sortChange')
    },
    goToRequisition(row: Project, column: Record<string, string>, cell: HTMLTableCellElement): void {
      if (!cell.classList.contains('actions')) {
        this.$router.push({
          name: 'requisition_edit',
          params: {
            projectId: row.id?.toString() ?? '',
            requisitionId: row.requisition?.id?.toString() ?? '',
          },
        })
      }
    },
    getStructureType(project: Project) {
      return project.structure?.type ?? null
    },
    getStructureName(project: Project): string {
      return project.structure?.name ?? '-'
    },
    getDoneItems(project: Project): string {
      let totalItems = 0
      let soldItems = 0

      if (project.requisition?.requisitionItems) {
        totalItems = project.requisition.requisitionItems.length
      }

      if (project.requisition?.requisitionItems && project.requisition?.requisitionItems?.length > 0) {
        project.requisition.requisitionItems.forEach((item) => {
          if (
            item.stockStatus == 'given_back' ||
            item.stockStatus == 'to_buyer_back' ||
            item.stockStatus == 'regrouped' ||
            item.stockStatus == 'stolen'
          ) {
            soldItems++
          }
        })
      }

      return `${soldItems} / ${totalItems}`
    },
    filterHandler(value: string, row: Record<string, string>, column: Record<string, string>) {
      const property = column['property']

      return row[property] === value
    },
    handleRequisitionCommand(command: DropdownAction) {
      // Edit requisition
      if (command.action == 'edit' && command.project) {
        this.editRequisition(command.project)
      }

      // Delete requisition
      if (command.action == 'delete' && command.project) {
        this.deleteProject(command.project)
      }

      // Delete requisition permanently
      if (command.action == 'delete_permanently' && command.project) {
        this.deleteProjectPermanently(command.project)
      }

      // Restore requisition
      if (command.action == 'restore' && command.project) {
        this.restoreProject(command.project)
      }
    },
    editRequisition(project: Project) {
      if (project.id) {
        this.$router.push({
          name: 'requisition_edit',
          params: {
            projectId: project.id?.toString() ?? '',
            requisitionId: project.requisition?.id?.toString() ?? '',
          },
        })
      }
    },
    deleteProject(project: Project) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer cette réquisition ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/project/${project.id}`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.$emit('getRequisitions')
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
      })
    },
    deleteProjectPermanently(project: Project) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer définitivement cette réquisition ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/project/${project.id}/permanent/requisition`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.$emit('getRequisitions')
          })
          .catch((error) => {
            const apiResponse = error.response.data as ApiResponse
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
      })
    },
    restoreProject(project: Project) {
      this.$api
        .put(`/project/${project.id}/restore`)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Succès',
            message: 'Opération réalisée avec succès !',
          })

          this.$emit('getProjects')
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
          })
        })
    },
    getStatusBadge(project: Project): Badge {
      switch (project.status) {
        case 'en_cours':
          return {
            color: '#EFE6FD',
            value: 'En cours',
          }

        case 'clos':
          return {
            color: '#EFE6FD',
            value: 'Clos',
          }

        default:
          return {
            color: '#EFE6FD',
            value: '-',
          }
      }
    },
    getStructureColor(project: Project) {
      return project.structure?.color
    },
  },
})
